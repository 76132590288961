import scrollTo from '@kissui/helpers/src/scrollTo'
import { WEB_COMPONENT_ANCHOR_LOADED, WEB_COMPONENT_PROJECTS_LOADED } from '@kissui/components'
import Deferred from '@kissui/helpers/src/deferred'

import '@kissui/components/src/container'
import '@kissui/components/src/cta'
import '@kissui/components/src/link'
import '@nespresso-design-system/assets/dist/icon.vanilla.css'
import '@nespresso-design-system/assets/dist/icon.vanilla'
import '@kissui/components/src/img'
import '@kissui/components/src/loader'
import '@kissui/components/src/add-to-cart'

const api = { Deferred }
window.nwcFoundations = api
const bindEvent = () => {
    if (window.location.hash && window.location.hash.substring(1)) {
        window.addEventListener(WEB_COMPONENT_ANCHOR_LOADED, scrollAfterComponentLoaded)
        window.addEventListener(WEB_COMPONENT_PROJECTS_LOADED, scrollAfterComponentLoaded)
    }
    window.addEventListener('hashchange', scrollAfterComponentLoaded)
}

const unbindEvent = () => {
    if (window.location.hash && window.location.hash.substring(1)) {
        window.removeEventListener(WEB_COMPONENT_ANCHOR_LOADED, scrollAfterComponentLoaded)
        window.removeEventListener(WEB_COMPONENT_PROJECTS_LOADED, scrollAfterComponentLoaded)
    }
}

async function scrollAfterComponentLoaded() {
    unbindEvent()
    if (!window.location.hash) {
        return
    }

    const target = window.location.hash.substring(1)
    if (!target) {
        return
    }

    await allSectionsReady()

    const $target = document.querySelector(`[data-id="${target}"]`)
    if (!$target) {
        return
    }

    await scrollTo($target)
}

async function allSectionsReady() {
    if (!window.nwc || !window.nwc.projects) {
        return
    }

    const promises = []

    window.nwc.projects.forEach(project =>
        project.components.forEach(
            component =>
                component.el && component.el.ready != null && promises.push(component.el.ready)
        )
    )

    await Promise.all(promises)
}

bindEvent()
