import createProps from '@kissui/helpers/src/props.helpers'

class Loader extends HTMLElement {
    constructor() {
        super()
        this.props = {}
    }

    connectedCallback() {
        this.props = createProps(this.attributes)
        this.render()
    }

    render() {
        const { icon = '24/symbol/status-loading', state = 'is-loading' } = this.props
        this.innerHTML = `<nb-icon icon="${icon}" class="${state}"></nb-icon>`
    }
}

customElements.get('nb-loader') || customElements.define('nb-loader', Loader)

export default Loader
