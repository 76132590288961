export const getDataLayer = () => {
    return window[window?.padlNamespace]?.dataLayer
}

export const getMarketCode = () => {
    const dataLayer = getDataLayer()
    if (!dataLayer) {
        return 'ch'
    }

    return dataLayer.app.app.market.toLocaleLowerCase()
}

export const getLangCode = () => {
    const dataLayer = getDataLayer()
    if (!dataLayer) {
        return 'en'
    }

    return getDataLayer().page.page.pageInfo.language.toLocaleLowerCase()
}

export const getSegmentCode = () => {
    const dataLayer = getDataLayer()
    if (!dataLayer) {
        return 'B2C'
    }

    return getDataLayer().page.page.pageInfo.segmentBusiness
}

export const isLoggedIn = () => {
    const dataLayer = getDataLayer()
    if (!dataLayer || !dataLayer.user) {
        return false
    }

    return dataLayer.user.isLoggedIn
}

export const interpolateMarketLang = (string, market, lang) => {
    return string.replace('{market}', market).replace('{lang}', lang)
}
