export const MAX_WIDTH_CONTAINER = 1160

export const BREAKPOINT_XL = 1920
export const BREAKPOINT_TABLET = 1024
export const BREAKPOINT_L = 996
export const BREAKPOINT_M = 768
export const BREAKPOINT_S = 750
export const BREAKPOINT_XS = 375

export const CTA_PRIMARY = 'primary'
export const CTA_PRIMARY_TRANSPARENT = 'primary_transparent'
export const CTA_SUBTLE = 'subtle'
export const CTA_LINK = 'link'
export const CTA_LINK_UNDERLINE = 'link-underline'
export const CTA_LINK_GOLD = 'link-gold'
export const CTA_LINK_ADD_TO_CART_SMALL = 'AddToCart_small'
export const CTA_LINK_ADD_TO_CART_LARGE = 'AddToCart_large'

export const EVENT_TAB_CHANGE = 'EVENT_TAB_CHANGE'

export const EVENT_VIDEO = 'WEB_COMPONENT_VIDEO'
export const EVENT_POPIN_OPEN = 'WEB_COMPONENT_POPIN_OPEN'
export const EVENT_POPIN_OPENED = 'WEB_COMPONENT_POPIN_OPENED'
export const EVENT_POPIN_CLOSE = 'WEB_COMPONENT_POPIN_CLOSE'
export const EVENT_POPIN_CLOSED = 'WEB_COMPONENT_POPIN_CLOSED'
export const EVENT_POPIN_CLOSE_CLICK = 'WEB_COMPONENT_POPIN_CLOSE_CLICK'
export const EVENT_POPIN_KEY_DOWN = 'keydown'
export const EVENT_POPIN_FORM_TOGGLE_TITLE = 'WEB_COMPONENT_POPIN_FORM_TOGGLE_TITLE'
export const EVENT_CTA_CLICK = 'WEB_COMPONENT_CTA_CLICK'
export const EVENT_HERO_REORDER_OPEN = 'WEB_COMPONENT_HERO_REORDER_OPEN'
export const EVENT_HERO_REORDER_CLOSE = 'WEB_COMPONENT_HERO_REORDER_CLOSE'
export const EVENT_QUICKVIEW_OPEN = 'WEB_COMPONENT_QUICKVIEW_OPEN'
export const EVENT_QS_OPEN = 'WEB_COMPONENT_QS_OPEN'
export const EVENT_QS_OPENED = 'WEB_COMPONENT_QS_OPENED'
export const EVENT_QS_CLOSE = 'WEB_COMPONENT_QS_CLOSE'
export const EVENT_QS_CLOSED = 'WEB_COMPONENT_QS_CLOSED'
export const EVENT_QS_CLOSE_CLICK = 'WEB_COMPONENT_QS_CLOSE_CLICK'
export const EVENT_QS_KEY_DOWN = 'keydown'
export const EVENT_ADD_TO_CART = 'WEB_COMPONENT_ADD_TO_CART'
export const EVENT_CART_UPDATED = 'WEB_COMPONENT_CART_UPDATED'
export const EVENT_VIDEO_TOGGLE = 'WEB_COMPONENT_VIDEO_TOGGLE'
export const VIDEO_ON_HOVER = 'VIDEO_ON_HOVER'
export const VIDEO_ON_OUT = 'VIDEO_ON_OUT'
export const EVENT_DETAIL_OPEN = 'WEB_COMPONENT_DETAIL_OPEN'
export const EVENT_SLIDER_READY = 'WEB_COMPONENT_SLIDER_READY'
export const EVENT_SLIDE_CHANGE = 'WEB_COMPONENT_SLIDE_CHANGE'
export const EVENT_OVERLAY_OPEN = 'WEB_COMPONENT_OVERLAY_OPEN'
export const EVENT_OVERLAY_CLOSE = 'WEB_COMPONENT_OVERLAY_CLOSE'
export const EVENT_OVERLAY_CLICKED = 'WEB_COMPONENT_OVERLAY_CLICKED'
export const EVENT_OPEN_PRODUCT_AR_CLICKED = 'OPEN_PRODUCT_AR_CLICKED'
export const EVENT_SORT_BY_CHANGE = 'WEB_COMPONENT_SORT_BY_CHANGE'

export const EVENT_BUBBLE_SELECTED = 'EVENT_BUBBLE_SELECTED'
export const EVENT_RECO_TOOL_OPTION_CLICKED = 'EVENT_RECO_TOOL_OPTION_CLICKED'
export const WEB_COMPONENT_PROJECTS_LOADED = 'WEB_COMPONENT_PROJECTS_LOADED'
export const WEB_COMPONENT_ANCHOR_LOADED = 'WEB_COMPONENT_ANCHOR_LOADED'

export const EVENT_SWIPED_UP = 'swiped-up'
export const EVENT_SWIPED_DOWN = 'swiped-down'
export const EVENT_SWIPED_LEFT = 'swiped-left'
export const EVENT_SWIPED_RIGHT = 'swiped-right'

export const EVENT_HEADER_POSITION_CHANGED = 'EVENT_HEADER_POSITION_CHANGED'

export const keys = { ESC: 'Escape' }

export const ADD_TO_CART_MODIFIER_MINI = 'mini'
export const ADD_TO_CART_MODIFIER_DEFAULT = ADD_TO_CART_MODIFIER_MINI

export const COFFEE_ORIGINAL = 'original'
export const COFFEE_VERTUO = 'vertuo'
export const COFFEE_PRO = 'pro'
export const COFFEE_OL = 'OL'
export const COFFEE_VL = 'VL'
export const INTENSITY_MAX_OL = 14
export const INTENSITY_MAX_VL = 12
export const DEFAULT_BUBBLE_ICON = ''

export const NUMBER_PRODUCTS_SLIDER = 8
export const NUMBER_FEATURES_PDP = 8

export const ALIGNMENT = ['center', 'left', 'right']
export const POSITION = ['top', 'right', 'bottom', 'left']
export const TRANSLATION_ADD_TO_CART = 'Add to cart'
export const TRANSLATION_UPDATE_BASKET = 'Update basket'

export const TIME_INSTANT = 1
export const TIME_FAST = 300
export const TIME_MEDIUM = 600
export const TIME_SLOW = 1200

export const APP_APPLE_LINK = {
    default: 'https://apps.apple.com/us/app/nespresso/id342879434',
    us: 'https://apps.apple.com/us/app/nespresso-new/id1609639566',
    uk: 'https://apps.apple.com/gb/app/nespresso-new/id1609639566'
}
export const APP_ANDROID_LINK =
    'https://play.google.com/store/apps/details?id=com.nespresso.activities'
export const APP_HUAWEI_LINK = 'https://appgallery.huawei.com/app/C102571517'

export const SRC_PAGE_PLP = 'plp'
export const SRC_PAGE_PDP = 'pdp'

export const PLP_TYPE_COFFEE = 'coffee'
export const PLP_TYPE_MACHINE = 'machine'
export const PLP_TYPE_ACCESSORY = 'accessory'
export const CALLEO_API_DOMAIN = 'https://www.contact.nespresso.com/'

// SCSS RELATED
// Todo : should be shared by JS and SCSS
export const BROWSER_CONTEXT = 16 // 1rem = 16px
export const COLOR_WHITE_1000 = '#FFFFFF' // Do not change for #FFF shortcut, it will break slider-natural gradients !

export const CONTRAST_DARK = 'dark'
export const CONTRAST_LIGHT = 'light'

export const B2B_CONTACT_FORM_POPIN_ID = 'b2b-contact-form-popin-id'
export const B2B_CONTACT_FORM_POPIN_SRC_SEARCH = 'coveo-search'

export const B2B_CONTACT_FORM_POPIN_SRC_SKU_MAIN_INFO = 'sku-main-info'

export const B2B_CONTACT_FORM_POPIN_SRC_SKU_MAIN_INFO_AUTO = 'sku-main-info-auto'

export const ASPECT_RATIO_16_9 = '16/9'
export const ASPECT_RATIO_1_1 = '1/1'

export const NESPRESSO_PRODUCTION_DOMAIN = 'https://www.nespresso.com'
export const NESPRESSO_ROLLOUT_DOMAIN = 'https://nc2-env-rollout.nespresso.com'

export const EVENT_QUIZ_ON_GO_BACK = 'WEB_COMPONENT_EVENT_QUIZ_ON_GO_BACK'
export const EVENT_QUIZ_SUBMIT = 'WEB_COMPONENT_EVENT_QUIZ_SUBMIT'
