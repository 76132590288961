export const dispatchEvent = ({ eventName, args, element }) => {
    element = element || window
    let event
    if (args) {
        event = new CustomEvent(eventName, { detail: args, bubbles: true })
    } else {
        if (typeof Event === 'function') {
            event = new Event(eventName)
        } else {
            event = document.createEvent('Event')
            event.initEvent(eventName, true, true)
        }
    }
    element.dispatchEvent(event)
}

export const readEvent = e => {
    if (!e.detail) {
        return
    }
    return e.detail
}
