import Viewport from './viewportTracking'
import { parseHTML, capitalize } from '../../utils'
import { productImpression } from '@kissui/helpers/src/gtmEvents'

export default function (node, productData, currency, range, position) {
    const tracking = new ProductTracking(node, productData, currency, range, position)

    return {
        destroy: () => {
            if (!tracking.getIom().hasIntersectionObserver()) {
                tracking.destroy()
            }
        }
    }
}

class ProductTracking extends Viewport {
    constructor(node, data, currency, range, position) {
        super(node)
        this.node = node
        this.data = data
        this.currency = currency
        this.range = range
        this.position = position
    }

    async runTrack() {
        // Remove impressionOnScoll event - from Analytics team (Luigi, Yahor)
        const noImpression =
            this.node.closest('[tracking_no_impression]')?.getAttribute('tracking_no_impression') ||
            false
        const productIndex =
            this.node.closest('[tracking_position]')?.getAttribute('tracking_position') || 0
        const list = this.node.closest('[tracking_list]')?.getAttribute('tracking_list') || ''

        if (noImpression !== 'true') {
            await productImpression(productIndex, list, this.data.id)
        }
    }

    async getPLPCategory(categoryId) {
        return (await window.getPLPData()).data.configuration.eCommerceData.categories.find(
            category => category.id === categoryId
        )
    }

    async getCategoriesWithPlpData(productData) {
        const TECHNOLOGY_CATEGORY_IDENTIFIER = '/machineTechno/'
        const isTechnologyCategory = categoryId =>
            categoryId.indexOf(TECHNOLOGY_CATEGORY_IDENTIFIER) !== -1

        const getTechnologyName = categoryId =>
            categoryId.substr(
                categoryId.indexOf(TECHNOLOGY_CATEGORY_IDENTIFIER) +
                    TECHNOLOGY_CATEGORY_IDENTIFIER.length
            )

        const categories = [
            ...(productData.cupSizes || []),
            ...(productData.flavors || []),
            ...(productData.ranges || []),
            ...(productData.technologies || [])
        ]

        const categoryNames = await Promise.all(
            categories.map(async categoryId => {
                const categoryData = await this.getPLPCategory(categoryId)

                if (!categoryData && isTechnologyCategory(categoryId)) {
                    return getTechnologyName(categoryId)
                }

                return categoryData.name
            })
        )

        return categoryNames.join('|')
    }

    async getCategories(productData) {
        if (!productData) {
            return ''
        }

        if (window.getPLPData) {
            return this.getCategoriesWithPlpData(productData)
        }

        return Promise.all(
            productData.supercategories.map(superCategory =>
                window.napi.catalog().getCategory(superCategory)
            )
        ).then(categories => {
            const dimension55 = []
            for (const category of categories) {
                const code = category.code
                if (!code) {
                    return
                }
                if (
                    ~code.indexOf('-cupSize-') ||
                    ~code.indexOf('-range-') ||
                    ~code.indexOf('capsule-aromatic-') ||
                    ~code.indexOf('machine-keyFeature-') ||
                    ~code.indexOf('machine-color-') ||
                    ~code.indexOf('accessory-collection-') ||
                    ~code.indexOf('accessory-hero-month')
                ) {
                    dimension55.push(category.name.toLowerCase())
                }
            }
            return dimension55.join('|')
        })
    }

    buildGTMProductObject(productData, dimension55) {
        let category
        if (productData.type === 'capsule') {
            category = 'capsules'
        } else if (productData.type === 'accessory') {
            category = 'accessories'
        } else if (productData.type === 'machine') {
            category = 'machines'
        }

        let dimension56 = null
        if (productData.technologies) {
            dimension56 = productData.technologies
                .map(technology => capitalize(technology.split('/')[2]))
                .join('|||')
        }

        let dimension130 = ''
        if (productData.productSelections != null) {
            dimension130 = productData.productSelections.join('|')
        }

        const impressionsOnScrollData = {
            brand: 'Nespresso',
            category,
            dimension43: 'false',
            dimension44: 'false',
            dimension53: productData.legacyId,
            dimension54: parseHTML(productData.name)[0].textContent,
            dimension55,
            dimension56,
            dimension57: this.isBundled(productData) ? 'bundled' : 'single',
            dimension130,
            id: productData.internationalId,
            name: parseHTML(productData.internationalName)[0].textContent,
            price: productData.price
        }
        if (this.range) {
            impressionsOnScrollData.list = this.range
        }
        if (this.position) {
            impressionsOnScrollData.position = parseInt(this.position)
        }
        return impressionsOnScrollData
    }

    /**
     * Guess if it's bundled even if the 'bundled' property is missing (ecapi VS PLP Data)
     */
    isBundled(productData) {
        let result = productData.salesMultiple === 1 && productData.unitQuantity > 1

        if (productData.bundled) {
            result = productData.bundled
        } else if (productData.salesMultiple > 1 && productData.unitQuantity === 1) {
            result = false
        }

        return result
    }
}
