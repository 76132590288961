import createProps from '@kissui/helpers/src/props.helpers'
import {
    handlePromoClick,
    productClick,
    trackComponentInteraction
} from '@kissui/helpers/src/gtmEvents'
import { dispatchEvent } from '@kissui/helpers/src/assets/js/eventDispatch'
import { EVENT_POPIN_OPEN, EVENT_QUICKVIEW_OPEN } from '../constants.mjs'
import { getLangCode, getMarketCode } from '@kissui/helpers/src/dataLayer'

class Link extends HTMLElement {
    constructor() {
        super()
        this.slot = this.innerHTML
        this.boundHandleClick = this.handleClick.bind(this)
        this.market = getMarketCode()
        this.language = getLangCode()
    }

    connectedCallback() {
        this.props = createProps(this.attributes)
        this.render()
        this.setClassHelper()
        this.bindEvents()
    }

    render() {
        const {
            size = 'large',
            color = 'gold',
            link = '',
            icon_right = '',
            icon_left = '',
            image = '',
            image_alt = ''
        } = this.props

        // Todo : can we get rid of this setAttributes, it's only needed because stories are using data
        size && this.setAttribute('size', size)
        color && this.setAttribute('color', color)
        image && this.setAttribute('image', image)
        image_alt && this.setAttribute('image_alt', image_alt)
        icon_left && this.setAttribute('icon_left', icon_left)
        icon_right && this.setAttribute('icon_right', icon_right)

        if (link === '') {
            this.renderButton()
        } else {
            this.renderLink()
        }

        this.link = this.firstElementChild
    }

    renderLink() {
        const {
            label,
            seo_label = '',
            tracking_label = '',
            link_new_tab = 'false',
            a11y_label_new_tab = '',
            icon_right = '',
            image = '',
            image_alt = '',
            icon_left = '',
            selected = '',
            phantom = ''
        } = this.props
        let { link } = this.props

        const slot = this.slot ? this.slot : label

        if (link) {
            link = link.replace('{market}', this.market)
            link = link.replace('{lang}', this.language)
        }

        this.innerHTML = `
        <a
            href="${link}"
            ${link_new_tab === 'true' ? 'target="_blank"' : ''}
            ${
                link_new_tab === 'true' && a11y_label_new_tab !== ''
                    ? `aria-description='${a11y_label_new_tab}'`
                    : ''
            }
            ${tracking_label && `tracking_label='${tracking_label}'`}
            ${seo_label ? `title="${seo_label}"` : ''}
            ${slot ? `aria-label="${slot}"` : ''}
            ${selected === 'true' ? 'aria-current="true"' : ''}
            ${phantom === 'true' ? `phantom="${slot}"` : ''}
        >
            ${icon_left && `<nb-icon icon='${icon_left}'></nb-icon>`}
            ${image && `<img src='${image}' alt='${image_alt}'/>`}
            <span>${slot}</span>
            ${icon_right && `<nb-icon icon='${icon_right}'></nb-icon>`}
        </a>`
    }

    renderButton() {
        const {
            label,
            tracking_label = '',
            icon_right = '',
            icon_left = '',
            selected = '',
            image = '',
            image_alt = '',
            phantom = ''
        } = this.props

        const slot = this.slot ? this.slot : label

        this.innerHTML = `
        <button
            ${tracking_label && `tracking_label='${tracking_label}'`}
            ${selected === 'true' ? 'aria-selected="true"' : ''}
            ${phantom === 'true' ? `phantom="${slot}"` : ''}
        >
            ${image && `<img src='${image}' alt='${image_alt}'/>`}
            ${icon_left && `<nb-icon icon='${icon_left}'></nb-icon>`}
            <span>${slot}</span>
            ${icon_right && `<nb-icon icon='${icon_right}'></nb-icon>`}
        </button>`
    }

    setClassHelper() {
        const { size = 'large', cta_link_type = 'false' } = this.props
        let sizeClass = ''

        switch (size) {
            case 'xsmall':
                sizeClass = 't-2xs-500-sl'
                break
            case 'small':
                sizeClass = 't-xs-500-sl'
                break
            case 'medium':
                sizeClass = 't-sm-400-sl'
                break
            case 'large':
                sizeClass = 't-sm-700-sl'
                break
            default:
                sizeClass = 't-sm-700-sl'
                break
        }

        if (cta_link_type === 'true') {
            sizeClass = 't-sm-700-sl'
            this.setAttribute('underline', 'false')
        }

        this.querySelector('a, button').classList.add(sizeClass)
    }

    handleClick() {
        this.track()

        const {
            popin_id = '',
            sku = '',
            tracking_position = '',
            tracking_list = '',
            sku_colors
        } = this.props

        if (popin_id && sku) {
            this.quickView(popin_id, sku, tracking_position, tracking_list, sku_colors)
            return
        }

        if (popin_id) {
            this.popin(popin_id)
        }
    }

    popin(popin_id) {
        dispatchEvent({ eventName: EVENT_POPIN_OPEN, args: { id: popin_id } })
    }

    quickView(popin_id, sku, tracking_position, tracking_list, colors) {
        const { sku_data } = this.props

        dispatchEvent({
            eventName: EVENT_QUICKVIEW_OPEN,
            args: {
                sku_colors: colors,
                id: popin_id,
                sku: sku,
                tracking_position: tracking_position,
                tracking_list: tracking_list
            }
        })

        if (sku_data) {
            const product = JSON.parse(sku_data)
            const componentName =
                tracking_list.indexOf(' - ') > -1 ? tracking_list.split(' - ')[0] : tracking_list
            trackComponentInteraction({
                creative: this.props.campaign_creative ?? '',
                actionType: componentName.toLowerCase() + ' quick view',
                internationalId: product.internationalId,
                internationalName: product.internationalName,
                productType: product.type,
                technology: product.technology,
                category: product.category,
                rawPrice: product.rawPrice,
                eventAction: componentName + ' Quick View'
            })
        }
    }

    track() {
        const {
            campaign_id = '',
            campaign_name = '',
            campaign_position = '',
            campaign_creative = '',
            campaign_instance_index = '',
            label,
            popin_id = '',
            long_sku = '',
            tracking_list = '',
            tracking_position = ''
        } = this.props

        const campaign = {
            id: campaign_id,
            name: campaign_name,
            position: campaign_position,
            creative: campaign_creative
        }

        const linkOrPopin = popin_id ? 'POPIN' : 'LINK'
        const slot = this.slot ? this.slot : label
        // value 0 is accepted as we need to pass the index
        const instanceIndex =
            campaign_instance_index || campaign_instance_index === 0
                ? ` - ${campaign_instance_index}`
                : ''

        if (tracking_list && tracking_position) {
            productClick(tracking_position, tracking_list, long_sku)
        } else {
            handlePromoClick({
                campaign,
                cta_name: `${linkOrPopin + instanceIndex} - ${slot.trim()}`
            })
        }
    }

    bindEvents() {
        this.unbindEvents()
        this.link.addEventListener('click', this.boundHandleClick)
    }

    unbindEvents() {
        this.link.removeEventListener('click', this.boundHandleClick)
    }

    disconnectedCallback() {
        this.unbindEvents()
    }
}

customElements.get('nb-link') || customElements.define('nb-link', Link)

export default Link
