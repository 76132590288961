const getData = attributes => attributes.find(attribute => attribute.nodeName === 'data')

const createProps = attributes => {
    const data = getData([...attributes])
    const props = [...attributes]
        .filter(attribute => attribute.nodeName !== 'data')
        .reduce((all, attr) => {
            return { ...all, [attr.nodeName]: attr.nodeValue }
        }, {})

    if (isNil(data)) {
        return props
    }

    try {
        return { ...props, ...JSON.parse(data.nodeValue) }
    } catch (error) {
        console.log('ERROR: No data', error, data?.nodeValue)
    }
}

const isNil = obj => obj === undefined || obj === null

export const parseBool = value => (!value || value === 'false' ? false : true)

export default createProps
