import { COFFEE_VERTUO } from '@kissui/components/src/constants.mjs'
import { getCurrency } from './getCurrency'
export { getCurrency } from './getCurrency'

export const ECAPI_TYPE_CAPSULE = 'capsule'
export const ECAPI_TYPE_MACHINE = 'machine'
export const ECAPI_TYPE_ACCESSORY = 'accessory'
export const ECAPI_TYPE_GIFT_CARD = 'giftcard'

const TECHNOLOGY_CATEGORY_IDENTIFIER = '/machineTechno/'
const SLEEVE_OF_10 = 10
const SLEEVE_OF_7 = 7

const trimSku = sku => sku.replace(/[^a-z0-9- +./]/gi, '')

export const getLegacySKU = productId => productId.split('prod/').pop()

export const getPriceFormatter = async () => await window.napi.priceFormat()

export const getProduct = sku => window.napi.catalog().getProduct(trimSku(sku))

export function getTechnologyName(productData) {
    const techno = productData.technologies[0]
    return techno.substring(
        techno.indexOf(TECHNOLOGY_CATEGORY_IDENTIFIER) + TECHNOLOGY_CATEGORY_IDENTIFIER.length
    )
}

function isMultipleOf(quantity, multiple) {
    return quantity % multiple === 0
}

export function getSleeveNumber(product, getTechnologyNameFn = getTechnologyName) {
    if (product.sales_multiple !== 1 && isMultipleOf(product.sales_multiple, SLEEVE_OF_10)) {
        // Sleeve of original or vertuo
        return product.sales_multiple / SLEEVE_OF_10
    }

    if (
        product.sales_multiple !== 1 &&
        getTechnologyNameFn(product) === COFFEE_VERTUO &&
        isMultipleOf(product.sales_multiple, SLEEVE_OF_7)
    ) {
        // Sleeve of vertuo
        return product.sales_multiple / SLEEVE_OF_7
    }

    if (product.sales_multiple === 1 && isMultipleOf(product.unitQuantity, SLEEVE_OF_10)) {
        // Bundle of original or vertuo
        return product.unitQuantity / SLEEVE_OF_10
    }

    if (
        product.sales_multiple === 1 &&
        getTechnologyNameFn(product) === COFFEE_VERTUO &&
        isMultipleOf(product.unitQuantity, SLEEVE_OF_7)
    ) {
        // Bundle of vertuo
        return product.unitQuantity / SLEEVE_OF_7
    }

    return NaN
}

/**
 * Guess if the product is part of a bundle.
 * This is determined by checking the 'sales_multiple' and 'unitQuantity' properties.
 */
export function isBundled(productData) {
    // TODO: this function should not change the productData, but some components still need it
    productData.sales_multiple = productData.sales_multiple || productData.salesMultiple

    const isSalesMultipleGreaterThanOne = productData.sales_multiple > 1
    const isUnitQuantityEqualToOne = productData.unitQuantity === 1
    const isSalesMultipleEqualToOne = productData.sales_multiple === 1
    const isUnitQuantityGreaterThanOne = productData.unitQuantity > 1

    // The product is not bundled if sales_multiple > 1 and unitQuantity = 1
    if (isSalesMultipleGreaterThanOne && isUnitQuantityEqualToOne) {
        return false
    }

    // The product is bundled if sales_multiple = 1 and unitQuantity > 1
    return isSalesMultipleEqualToOne && isUnitQuantityGreaterThanOne
}

/**
 * Replace an array of SKU with product data
 * Remove the element inside the list if sku fail. It prevents to get an empty item.
 * @param {Array.<String>} skus
 * @param getProductFn
 * @returns {Promise<Awaited<unknown>[]>}
 */
export function getProductAndClean(skus, getProductFn = getProduct, napi = window.napi) {
    const promises = []

    if (!napi) {
        return Promise.reject()
    }

    if (!Array.isArray(skus)) {
        skus = [skus]
    }

    // Load manually the product to delete SKU fail
    for (const sku of skus) {
        promises.push(
            getProductFn(sku)
                .then(data => skus.splice(skus.indexOf(sku), 1, data))
                .catch(() => {
                    skus.splice(skus.indexOf(sku), 1)
                    console.error(`getProduct fail on sku ${sku}`)
                })
        )
    }

    return Promise.all(promises)
}

export const getFormattedPrice = async price => {
    const priceFormatter = await getPriceFormatter()
    const currency = getCurrency()

    return priceFormatter.html
        ? priceFormatter.html(currency, price)
        : priceFormatter.short(currency, price) || ''
}

export const getProductCategories = async sku => {
    const productDetails = await getProduct(sku)
    const productCategories = productDetails ? productDetails.supercategories : []
    const productCategoriesNew = await Promise.all(
        productCategories.map(async categoryEncoded => {
            const productCategoryData = await window.napi.catalog().getCategory(categoryEncoded)
            return productCategoryData
        })
    )
    return productCategoriesNew
}
