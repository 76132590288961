/**
 * We removed duplicated values to improve the performances
 * Here are original values :
 * small: 16, 24, 32, 36, 48, 64, 72, 96, 108, 120, 128, 144, 150, 180, 192, 216, 225, 240, 288, 300, 360, 384, 450, 480, 600
 * medium: 180, 240, 270, 360, 450, 480, 512, 540, 600, 675, 720, 768, 800, 900, 960, 1000, 1024, 1080, 1200, 1350, 1440, 1500, 1536, 1600, 1800, 2000, 2048, 2400, 3000, 3200, 4000
 * large: 640, 720, 800, 960, 1024, 1080, 1200, 1280, 1440, 1536, 1600, 1920, 2048, 2160, 2400, 2560, 2880, 3072, 3200, 3840, 4096
 * product: 45, 50, 58, 60, 65, 90, 100, 120, 130, 135, 150, 174, 180, 195, 200, 230, 232, 240, 260, 300, 360, 400, 450, 460, 480, 600, 690, 720, 800, 900, 960, 1200
 *
 * Useful documentation:
 * IT documentation on IM Policy (Image Manager Policy) profiles and resolutions: https://dsu-confluence.nestle.biz/x/XAYoC
 * Our IM Policy documentation: https://dsu-confluence.nestle.biz/x/zQjrCg
 * Our IM Policy study on page builder sections: https://dsu-confluence.nestle.biz/x/1tb3Cg
 */

export const LAZYLOAD_CUSTOM = 'custom'
export const LAZYLOAD_NATIVE = 'native'

export const PROFILES = [
    {
        name: 'small',
        sizes: [
            16, 24, 32, 36, 48, 64, 72, 96, 108, 120, 128, 144, 150, 180, 192, 216, 225, 240, 288,
            300, 360, 384, 450, 480, 600
        ]
    },
    {
        name: 'medium',
        sizes: [
            270, 512, 540, 675, 720, 768, 800, 900, 960, 1000, 1024, 1080, 1200, 1350, 1440, 1500,
            1536, 1600, 1800, 2000, 2048, 2400, 3000, 3200, 4000
        ]
    },
    {
        name: 'large',
        sizes: [640, 1280, 1920, 2160, 2560, 2880, 3072, 3840, 4096]
    },
    {
        name: 'product',
        sizes: [
            45, 50, 58, 60, 65, 90, 100, 130, 135, 174, 195, 200, 230, 232, 260, 300, 400, 460, 690
        ]
    }
]
