import productTracking from '@kissui/helpers/src/assets/js/productTracking'
import { getProduct } from '@kissui/helpers/src/catalog'

class AddToCart extends HTMLElement {
    static addToCartCounter = 0

    connectedCallback() {
        this.sku = (this.getAttribute('sku') || '').trim()
        this.range = this.getAttribute('range')
        this.position = this.getAttribute('position')
        this.longSku = (this.getAttribute('longSku') || '').trim()
        this.buttonSize = this.getAttribute('buttonSize') || 'small' // large or small
        this.rendererName = this.getAttribute('rendererName') || 'default'
        this.elementID = `addToCart${AddToCart.addToCartCounter++}`

        this.render()

        if (!this.isWindowNapiActive()) {
            return
        }

        window.napi
            .getConfig()
            .then(() => {
                this.pushButton()
            })
            .catch(e => console.error(e))

        this.tracking()
            .then(() => {})
            .catch(e => console.error(e))
    }

    render() {
        this.innerHTML = `<div class="cb-AddToCart" id="${this.elementID}">
                <button class='AddToBagButtonLarge'>
                    <span class='AddToBagButtonLarge__label'>+</span>
                </button>
            </div>`
    }

    isWindowNapiActive() {
        return !!window.napi
    }

    pushButton() {
        if (!document.getElementById(this.elementID)) {
            return
        }

        const buttonSize = this.buttonSize

        window.ui = window.ui || []
        window.ui.push({
            id: this.elementID,
            module: 'AddToBagButton',
            configuration: { props: { productId: this.longSku, buttonSize } },
            ecommerceData: { activated: true }
        })
    }

    async tracking() {
        if (!this.isWindowNapiActive()) {
            return Promise.resolve()
        }

        const data = await this.getProductData()
        const currency = window[window.config.padl.namespace].dataLayer.app.app.currency
        productTracking(this, data, currency, this.range, this.position)
    }

    getSkuKey(sku) {
        return sku.indexOf('prod/') !== -1 ? 'id' : 'legacyId'
    }

    async getProductData() {
        let plpData = null
        if (window.getPLPData != null) {
            plpData = await window.getPLPData()
        }
        if (plpData) {
            const skuKey = this.getSkuKey(this.sku)
            const allKeys = [skuKey, 'internationalId', 'id', 'legacyId']
            let currentKeyIndex = 0
            let data = null

            while (!data && currentKeyIndex < allKeys.length - 1) {
                const key = allKeys[currentKeyIndex]

                data = plpData.data.configuration.eCommerceData.products.find(
                    product => product[key] === this.sku
                )

                currentKeyIndex++
            }

            return data
        } else if (this.isWindowNapiActive()) {
            return await getProduct(this.longSku)
        }

        console.error('Error: napi is not found.')
    }
}

customElements.get('nb-add-to-cart') || customElements.define('nb-add-to-cart', AddToCart)

export default AddToCart
