import Deferred from './deferred'

const resolveScrollPosition = (scrollTo: HTMLElement | string | number, scrollPaddingTop = 140) => {
    if (typeof scrollTo === "number") {
        return scrollTo
    }

    const scrollToElement = typeof scrollTo === "object" ? scrollTo : document.querySelector(scrollTo)

    if (scrollToElement === null && typeof scrollTo === "string") {
        throw new Error(`error: No element found with the selector "${scrollTo}"`)
    }

    return window.scrollY + scrollToElement!.getBoundingClientRect().top - scrollPaddingTop
}

const resolveScrollDuration = (scrollDuration: number, deltaScroll: number) => {
    const viewportHeight = window.innerHeight
    const bodyHeight = document.body.clientHeight

    if (deltaScroll > viewportHeight && bodyHeight - viewportHeight > 0) {
        return Math.abs(
            scrollDuration +
            scrollDuration * (Math.abs(deltaScroll) / (bodyHeight - viewportHeight))
        )
    }
    return scrollDuration
}
/**
 * Smooth scroll-to inspired by:
 * http://stackoverflow.com/a/24559613/728480
 */
const scrollTo = (scrollTo: HTMLElement | string | number = 0, options: { scrollPaddingTop?: number, scrollDuration?: number } = {}) => {
    const { scrollDuration: baseScrollDuration = 300 } = options
    const deferred = new Deferred()

    const scrollPosition = resolveScrollPosition(scrollTo, options.scrollPaddingTop)

    let currentTime = 0
    const initialTimestamp = window.performance.now()
    const initialScroll = window.scrollY
    const deltaScroll = initialScroll - scrollPosition

    const scrollDuration = resolveScrollDuration(baseScrollDuration, deltaScroll)

    const finalTimestamp = initialTimestamp + scrollDuration

    function step() {
        const currentTimestamp = window.performance.now()
        if (currentTimestamp < finalTimestamp) {
            currentTime = (currentTimestamp - initialTimestamp) / scrollDuration
            currentTime = --currentTime * currentTime * currentTime + 1
            const moveStep = deltaScroll * currentTime
            window.scrollTo(0, scrollPosition + (deltaScroll - moveStep))
            window.requestAnimationFrame(step)
        } else {
            window.scrollTo(0, scrollPosition)
            setTimeout(() => deferred.resolve(undefined), 125)
        }
        return deferred.promise
    }

    window.requestAnimationFrame(step)
    return deferred.promise
}

export default scrollTo
