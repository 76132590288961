import createProps from '@kissui/helpers/src/props.helpers'
import { lazyLoad } from '@kissui/helpers/src/utils'
import { PROFILES, LAZYLOAD_CUSTOM, LAZYLOAD_NATIVE } from './js/constants'

/** IMAGE COMPONENT
 * Important prerequisite!
 * For the ImPolicy and CLS we need to assume that the width of the component is set by the parent component
 *
 * IM Policy annoying behaviour:
 * Also IM Policy is returning in some cases more than 2x images (based in device pixel density auto detection e.g. Iphone 11 pro returns x3)
 * We don't want this behaviour so we need to force x1 by adding an extra param &imdensity=1
 *
 * @Params
 * - file: path of your image
 * - description: img alt description. If empty, set alt='image' to avoid A11y errors + aria-hidden="true" because we assume it's a decorative image
 *   (we don't use alt='' because if void it does not allow the error message to be displayed, not creating :before and :after pseudo element on img)
 * - aspect_ratio: (default 1:1) is managing the CLS including a blinking animation
 * - force_width: use this if for any reason you need to force the image width to ask to akamai (e.g. image cropped on both sides like in card-lifestyle tablet)
 * - force_height: use this if in the layout you cannot rely on width but in height
 * - lazyload: (default custom / native / no) is managing fadein animation + error message
 * - impolicy: (default true) is checking automatically the right profile and 'real' end resolution
 * - placeholder_color_light: (default black_100) is the lighter color for CLS animation
 * - placeholder_color_dark: (default black_250) is the darker color for CLS animation
 * - object-fit: (default: cover) use 'contain' instead (applys object-fit: contain on img) if you want to image to be displayed always fully
 *   like in sku component, where the product image have different original aspect_ratio, we want to display always the full product
 */

class Img extends HTMLElement {
    constructor() {
        super()
    }

    connectedCallback() {
        this.props = createProps(this.attributes)
        this.render()
        this.#initLazyLoad()
    }

    #initLazyLoad() {
        if (this.lazyload !== LAZYLOAD_CUSTOM) {
            return
        }
        const callbackSuccess = () => {
            this.classList.remove('loading')
        }

        lazyLoad(this.firstElementChild, 'src', `${this.file}`, this.file, { callbackSuccess })
    }

    render() {
        const {
            file = '',
            description = '',
            lazyload = LAZYLOAD_CUSTOM,
            fetchpriority = ''
        } = this.props

        if (file === '') {
            return
        }

        // Set properties used elsewhere in component
        this.lazyload = lazyload
        this.file = file + this.#setImPolicyQuery()

        this.#setCssProperties()
        this.classList.add('nb-img')
        this.innerHTML = `
            <img
                ${this.lazyload === LAZYLOAD_CUSTOM ? 'src=""' : `src="${this.file}"`}
                ${description !== '' ? `alt="${description}"` : 'alt="image" aria-hidden="true"'}
                ${this.lazyload === LAZYLOAD_NATIVE ? 'loading="lazy"' : ''}
                ${fetchpriority !== '' ? `fetchpriority=${fetchpriority}` : ''}
                class="loading"
             />`
        // Get this element's first class
        this.parentClass = this.getAttribute('class').split(' ')[0]

        // Add that previous class + a suffix to its first child
        if (this.parentClass !== '') {
            this.firstElementChild.classList.add(this.parentClass + '-content')
        }
    }

    #setImPolicyQuery() {
        const { aspect_ratio, force_width, force_height, impolicy = true } = this.props

        if (impolicy === 'false') {
            return ''
        }

        let width = this.offsetWidth

        if (force_width && force_width !== '') {
            width = Number(force_width)
        }

        if (force_height && force_height !== '') {
            const [w, h] = aspect_ratio.split('/')
            width = Math.round((Number(force_height) * w) / h)
        }

        let finalWidth = this.#getRetinaWidth(width)

        const impolicy_profil = this.#getImpolicyProfile(finalWidth)

        return `?impolicy=${impolicy_profil}&imwidth=${finalWidth}&imdensity=1`
    }

    #getRetinaWidth(width) {
        const { devicePixelRatio } = window
        return devicePixelRatio > 1 ? width * 2 : width
    }

    #getImpolicyProfile(width) {
        const { exactFound, closests } = this.#getClosests(width)

        if (exactFound) {
            return exactFound.name
        } else {
            let tempDiff = closests[0].diff
            let closest = null
            closests.forEach(item => {
                if (item.diff <= tempDiff) {
                    tempDiff = item.diff
                    closest = item
                }
            })
            return closest.name
        }
    }

    #getClosests(width) {
        let exactFound = null
        let closests = []
        PROFILES.every(profile => {
            const found = profile.sizes.find(el => el >= width)

            if (found) {
                if (found === width) {
                    exactFound = profile
                    return false
                }

                closests.push({ name: profile.name, diff: found - width })
            }

            return true
        })
        return { exactFound, closests }
    }

    #setCssProperties() {
        const {
            aspect_ratio,
            object_fit,
            placeholder_color_light,
            placeholder_color_dark,
            error_message
        } = this.props

        this.#setCssProperty('--image-aspect-ratio', aspect_ratio)
        this.#setCssProperty('--image-object-fit', object_fit)
        this.#setCssProperty('--image-placeholder-color-light', placeholder_color_light)
        this.#setCssProperty('--image-placeholder-color-dark', placeholder_color_dark)
        this.#setCssProperty('--image-placeholder-error-text', '"' + error_message + '"')
    }

    #setCssProperty(name, value) {
        if (!value || value === '"undefined"') {
            return
        }

        this.style.setProperty(name, value)
    }
}

customElements.get('nb-img') || customElements.define('nb-img', Img)

export default Img
